'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import GoogleMap from '@components/Widgets/GoogleMap.react';
import SearchKeywords from '@components/Search/Filters/SearchKeywords.react';
import ReportIssue from '@components/Widgets/ReportIssue.react';

import AuthStore from '@stores/AuthStore'
import UserStore from '@stores/UserStore';
import UserActions from '@actions/UserActions';

import Analytics from '@utils/Analytics';
import { getConfig } from '@utils/Env';
import { roundForHumans, roundForHumansByUnit, getKmBetweenCoordinates } from '@utils/Math';

import './Locations.scss';

export default class RestaurantLocations extends Component {

    static propTypes = {
        onSelectLocation: PropTypes.func,
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            locationParams: this.getDefaultLocationParams(context.addSwapContext),
            groupedLocations: [],
            locations: [],
            totalLocations: 0,
            showSearchHere: false,
            showAutocomplete: false,
            loading: true,
        };

        this.debounceSearchLocations = debounce(this.searchLocations, 400);
    }

    map = null;
    locationSearchKey = null;

    realizeMap = (map) => {
        this.map = map;
    }

    /**
     * Converts the parameters we get from the parent AddSwapRecipe modal to a restaurant search parameters
     * @param  {[type]} inputs [description]
     * @return {[type]}        [description]
     */
    getDefaultLocationParams = ({profile, mealType}) => {
        let mealTypeTag = mealType;

        if (mealTypeTag === 'Dinner') {
            mealTypeTag = 'Main Dish';
        }

        const params = {
            types: ['brand_location'],
            terms: '',
            filters: {
                matching_products: {
                    language: profile.language || 'en',
                    tags: [mealTypeTag],
                },
            },
        };

        return params;
    }

    computeDistanceToLocationResults = (locations) => {
        if (!this.map) {
            return locations;
        }

        const currentLocation = this.map.currentLocation;

        locations.forEach(location => {
            location.distance_km = getKmBetweenCoordinates(
                currentLocation.lat, currentLocation.lon,
                location.location.lat, location.location.lon
            );
        });

        return locations;
    }

    groupLocations = (locations) => {
        let collector = {};

        locations.forEach(location => {
            collector[location.parent] = collector[location.parent] || [];
            collector[location.parent].push(location);
        });

        Object.keys(collector).forEach(brandUuid => {
            // Sort the collector's locations by their distance

            collector[brandUuid] = collector[brandUuid].sort((a, b) => {
                if (a.distance_km < b.distance_km) return -1;
                if (a.distance_km > b.distance_km) return 1;

                return 0;
            });
        });

        // Sort the collector, changing type from object to array
        collector = Object.values(collector).sort((a, b) => {
            if (a[0].distance_km < b[0].distance_km) return -1;
            if (a[0].distance_km > b[0].distance_km) return 1;

            return 0;
        });

        return collector;
    }

    searchLocations = async () => {
        // Deep clone parameters
        const locationSearchKey = JSON.stringify(this.state.locationParams);

        if (locationSearchKey === this.locationSearchKey) {
            return;
        }

        this.locationSearchKey = locationSearchKey;
        const params = JSON.parse(locationSearchKey);
        params.size = 250;

        this.setState({loading: true});

        Analytics.searchRestaurantLocations();

        let results;

        try {
            results = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
                body: JSON.stringify(params),
            }, true);
        } catch (error) {
            this.setState({loading: false, error: error.message});
            return;
        }

        const locations = this.computeDistanceToLocationResults(results.elements);

        // Is the map rendered? Clear, then add the markers
        if (this.map) {
            this.map.deleteMarkers();

            // Create a marker for my current location using the blue marker pin
            this.map.createCurrentLocationMarker();

            locations.forEach(establishment => {
                establishment.marker = this.map.addMarker(
                    {lat: establishment.location.lat, lng: establishment.location.lon},
                    establishment.title,
                    this.renderEstablishmentInfo(establishment),
                );
            });
        }

        const groupedLocations = this.groupLocations(results.elements);

        this.setState({
            locations,
            groupedLocations,
            totalLocations: results.total,
            loading: false,
            showSearchHere: false,
        });
    }

    onMapInitalized = () => {
        this.onSearchHere();
    }

    onFocusSearch = () => {
        this.setState({showAutocomplete: true});
    }

    onBlurSearch = () => {
        this.setState({showAutocomplete: false});
    }

    onChangeLocationParams = (locationParams) => {
        this.setState({locationParams}, this.debounceSearchLocations);
    }

    onSearchHere = () => {
        if (!this.map) {
            return;
        }

        const bounds = this.map.getBounds();

        if (!bounds) {
            return;
        }

        const { locationParams } = this.state;

        locationParams.filters = locationParams.filters || {};
        locationParams.filters.geo_bounds = locationParams.filters.geo_bounds || {};

        // Transform top/right & bottom/left <= to => top/left & bottom/right
        const ne = bounds.getNorthEast(),
              sw = bounds.getSouthWest();

        locationParams.filters.geo_bounds.top = ne.lat();
        locationParams.filters.geo_bounds.left = sw.lng();
        locationParams.filters.geo_bounds.bottom = sw.lat();
        locationParams.filters.geo_bounds.right = ne.lng();

        this.setState({locationParams}, this.searchLocations);
    }

    onPlaceChanged = () => {
        this.onSearchHere();
    }

    onBoundsChanged = (bounds) => {
        this.setState({showSearchHere: true});
    }

    onSelectLocation = (location) => {
        const { onSelectLocation } = this.props;

        onSelectLocation(location);
    }

    onLocationFound = (currentLocation) => {
        this.onSearchHere();

        // Courtesy update of the user store's location, so we have this exact location available for later.
        // when the map isn't being rendered.
        UserActions.updateCurrentLocation(currentLocation.lat, currentLocation.lon);

        Analytics.userLocated({
            'Context': 'Restaurant Search',
            'Latitude': currentLocation.lat,
            'Longitude': currentLocation.lon,
        });
    }

    renderEstablishmentInfo = (location) => {
        const { title, address1, address2, distance_km, yelp_price, matching_products } = location;
        const { units_mode = 'english' } = UserStore.getUser() || {};

        return (
            <div className="establishment-info" onClick={() => this.onSelectLocation(location)}>
                <p className="matching">{matching_products} {matching_products === 1 ? 'dish' : 'dishes'}</p>
                <h3>{title}</h3>
                <p>
                    {units_mode === 'metric' ? <span>{roundForHumans(distance_km)}km - {address1}</span> : null}
                    {units_mode === 'english' ? <span>{roundForHumans(distance_km * 0.621371)}mi - {address1}</span> : null}
                    <span className="yelp-price"> - <em>{('$').repeat(yelp_price)}</em></span>
                </p>
            </div>
        );
    }

    renderLocationGroupResult = (group, i) => {
        const closest = group[0];
        const { units_mode = 'english' } = UserStore.getUser() || {};

        return (
            <div key={i} className="location-result-item" onClick={() => this.onSelectLocation(closest)}>
                <span className="matching">{closest.matching_products} {closest.matching_products === 1 ? 'dish' : 'dishes'}</span>

                <h3>{closest.title}</h3>
                <p>
                    {units_mode === 'metric'
                        ? <span>{roundForHumans(closest.distance_km)}km</span>
                        : <span>{roundForHumans(closest.distance_km * 0.621371)}mi</span>
                    }
                    <span>- {closest.address1}</span>
                    <span className="yelp-price"> - <em>{('$').repeat(closest.yelp_price)}</em>{('$').repeat(4-closest.yelp_price)}</span>
                </p>
            </div>
        );
    }

    render = () => {
        const { locationParams, showSearchHere, showAutocomplete, locations, groupedLocations, loading } = this.state;

        return (
            <div className="restaurant-locations">
                <div className="outer-map-container">
                    <SearchKeywords params={locationParams}
                        onFocus={this.onFocusSearch}
                        onBlur={this.onBlurSearch}
                        className="map-keywords-filter"
                        onChangeParams={this.onChangeLocationParams}
                        placeholder="Search" />

                    <GoogleMap ref={this.realizeMap}
                        onBoundsChanged={this.onBoundsChanged}
                        onPlaceChanged={this.onPlaceChanged}
                        onMapInitalized={this.onMapInitalized}
                        onLocationFound={this.onLocationFound}
                        onSearchHere={this.onSearchHere}
                        showSearchHere={showSearchHere}
                        showAutocomplete={true} />
                </div>

                <div className="outer-results-container">
                    <div className="inner-results-container">
                        <ReportIssue />

                        {loading ?
                            <div className="loading">
                                <h2>Loading...</h2>
                                <i className="icon-spinner" />
                            </div>
                        : null}

                        {groupedLocations.map(this.renderLocationGroupResult)}

                        {!loading && locations.length == 0 && locationParams.terms ?
                            <div className="no-results-found">
                                <h2>Sorry, we couldn't find {locationParams.terms}</h2>
                                <p>We don’t currently have this store’s meal information. If you would like to still eat foods from this store, tell us what you will be eating there so we can track nutrition information.</p>

                                <footer>
                                    <button className="create-meal-btn" onClick={this.showCreateCustom}>Create a Meal</button>
                                </footer>
                            </div>
                        : null}

                        {!loading && locations.length == 0 && !locationParams.terms ?
                            <div className="no-results-found">
                                <h2>Sorry, we couldn't find anything in this area</h2>
                                <p>Thanks for your patience. We are working on adding new restaurants every day.</p>
                                <p>In the meantime, create a custom meal for your favorite local restaurant. This will allow you to track this meal in your planner and log.</p>
                                <footer>
                                    <button className="create-meal-btn" onClick={this.showCreateCustom}>Create a Custom Meal</button>
                                </footer>
                            </div>

                        : null}

                    </div>
                </div>
            </div>
        );
    }
}
