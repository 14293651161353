'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Link } from 'react-router';
import { Plugins } from '@capacitor/core';

import Popup from '../Popup.react';
import GroceriesDropdown from './GroceriesDropdown.react';
import NotificationsDropdown from './NotificationsDropdown.react';
import SetPassword from '../header/SetPassword.react';
import DownForMaintenanceModal from './DownForMaintenanceModal.react';
import MyDietitianInfo from './MyDietitianInfo.react';
import Alert from '../Alert/Alert.react';

import SideNav from './OffCanvas.react';
import Analytics from '../../../utils/Analytics';
import UserActions from '../../../actions/UserActions';
import UserStore from '../../../stores/UserStore';
import AuthStore from '../../../stores/AuthStore';
import ChatStore from '../../../stores/ChatStore';

import { getConfig } from '../../../utils/Env';

import './Header.scss';

const VERSION_POLL_INTERVAL = 15 * 60; // 15 minutes worth of seconds

export default class Header extends Component {

    static propTypes =  {
        showSignIn: PropTypes.bool,
        showSignUp: PropTypes.bool,
        location: PropTypes.object,

        hideBackButton: PropTypes.bool,
        hideNavigation: PropTypes.bool,
        hideHeaderButtons: PropTypes.bool,
    };

    static defaultProps = {
        showSignIn: true,
        showSignUp: true,
        showDietitians: true,
    };

    static contextTypes = {
        router: PropTypes.object,
        showLoginForm: PropTypes.func,
        isMobile: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        const search = props.location.query.terms || '';

        this.state = {
            user: UserStore.getUser(),

            windowScroll: 0,

            latestVersion: getConfig('build_number'),
            downForMaintenance: null,
        }

        this.handleScroll = debounce(this.handleScroll, 50);
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);

        if (process.browser) {
            window.addEventListener('scroll', this.handleScroll);
            document.addEventListener(this.getVisibilityChangeEventName(), this.handleVisibilityChange);
            this.startVersionPollInterval();
        }

        if (Plugins && Plugins.App) {
            Plugins.App.addListener('appStateChange', this.onAppStateChange);
        }
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);

        if (process.browser) {
            window.removeEventListener('scroll', this.handleScroll);
            document.removeEventListener(this.getVisibilityChangeEventName(), this.handleVisibilityChange);
        }
    }

    versionPollInterval = null;
    versionPollPromise = null;
    disconnectTimeout = null;

    // scheduleDisconnect = () => {
    //     this.cancelDisconnect();
    //     this.disconnectTimeout = setTimeout(async () => {
    //         await ChatStore.disconnect();
    //         console.log("Disconnected after 5 minutes away");
    //     }, 300000);
    // };

//TO DO: set back to 300000
    scheduleDisconnect = () => {
        this.cancelDisconnect();
        this.disconnectTimeout = setTimeout(async () => {
            await ChatStore.disconnect();
            console.log("Disconnected after 5 minutes away");
        }, 1000);
    };


    cancelDisconnect = () => {
        if (this.disconnectTimeout) {
            clearTimeout(this.disconnectTimeout);
            this.disconnectTimeout = null;
            console.log("User returned, canceling disconnect");
        }
    };

    stopVersionPollInterval = () => {
        if (this.versionPollInterval) {
            clearInterval(this.versionPollInterval);
            this.versionPollInterval = null;
        }

        this.versionPollPromise = null;
    }

    startVersionPollInterval = () => {
        this.stopVersionPollInterval();

        this.pollForVersion(true);
        this.versionPollInterval = setInterval(this.pollForVersion, VERSION_POLL_INTERVAL * 1000);
    }

    pollForVersion = () => {
        const { latestVersion, downForMaintenance } = this.state;
        // Don't check for build numbers in dev.
        if (getConfig('build_number') === 'local' || latestVersion === 'local') {
            return;
        }

        // Do not poll if we're already out of date.
        if (!downForMaintenance && latestVersion != getConfig('build_number')) {
            return;
        }

        // Do not make multiple simultaneous requests
        if (this.versionPollPromise) {
            return;
        }

        try {
            this.versionPollPromise = AuthStore.fetch('https://' + getConfig('self_host') + '/version', {}, true).then(
                success => {
                    this.versionPollPromise = null;

                    if (downForMaintenance && !success.down) {
                        location.reload();
                    } else {
                        this.setState({latestVersion: success.version, downForMaintenance: success.down});
                    }
                },
                error => {
                    this.versionPollPromise = null;

                    let found = null;

                    if (error && error.text && (found = error.text.match(/down for maintenance: ([.\s\S]*?) EOM/))) {
                        this.setState({downForMaintenance: found[1]});
                    }
                },
            );
        } catch (exp) {
            this.versionPollPromise = null;
        }
    }

    isAppHidden = () => {
        // Set the name of the hidden property and the change event for visibility
        var hidden;

        if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
              hidden = 'hidden';
        } else if (typeof document.msHidden !== 'undefined') {
              hidden = 'msHidden';
        } else if (typeof document.webkitHidden !== 'undefined') {
              hidden = 'webkitHidden';
        }

        return (document && document[hidden]) ? true : false;
    }

    /**
     * HAACk, *cough*cough*.  Wooo! That's some good shit right there. *HAAAACKC*burp*.
     *
     * @return {[type]} [description]
     */
    getVisibilityChangeEventName = () => {
        var eventName;

        if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
          eventName = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
          eventName = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
          eventName = 'webkitvisibilitychange';
        }

        return eventName;
    }

    onAppStateChange = (state) => {
        const { isActive = true } = state || {};

        if (isActive) {
            this.startVersionPollInterval();

            // Synchronize a buttload of stuff. This also reconnects to the chat server.
            UserActions.synchronizeUser();

            this.cancelDisconnect();
        } else if (window.cordova) {
            let taskId = Plugins.BackgroundTask.beforeExit(async () => {
                // We'll be polling when we come back, stop polling
                this.stopVersionPollInterval();

                // Start a 5-minute timeout before disconnecting
                this.scheduleDisconnect();

                // Then let the operating system know it's ok to keep backgrounding.
                Plugins.BackgroundTask.finish({taskId});
            });
        }
    };

    handleVisibilityChange = () => {
        if (!process.browser) {
            return;
        }

        const isAppHidden = this.isAppHidden();

        if (isAppHidden) {
            this.scheduleDisconnect();
        }
        
        this.onAppStateChange({ isActive: !isAppHidden });
    };

    handleScroll = () => {
        this.setState({windowScroll: window.scrollY});
    }

    onUserStoreChange = () => {
        this.setState({user: UserStore.getUser()});
    }

    logout = () => {
        this.setState({user: false});
        UserActions.logout();
    }

    onClickSignIn = () => {
        const { showLoginForm } = this.context;

        showLoginForm();
    }

    shouldShowFirstPasswordTooltip = (user, location) => {
        if (!user) {
            return false;
        }

        // Are we on any of the banned pages?
        const { pathname = '/meals' } = location;

        // Don't show on any of the preferences screens
        if (pathname.includes('/my-account') ||
            pathname.includes('/preferences') ||
            pathname.includes('/privacy') ||
            pathname.includes('/terms')) {
            return false;
        }

        // Don't show on the new patient onboarding page
        if (pathname.includes('/accept')) {
            return false;
        }

        // Don't show on the customizer
        if (pathname.includes('/menus')) {
            return false;
        }

        return !user.is_password_set;
    }

    renderHeaderButtons = () => {
        const { isMobile } = this.context;
        let { user } = this.state;
        const { location, showSignIn, showSignUp, showDietitians, hideNavigation } = this.props;

        if (!user) {
            let links = [];

            if (showDietitians) {
                const proUrl = 'https://' + getConfig('pro_host');
                links.push(
                    <a target="_blank" href={proUrl} className="el-medium-btn el-medium-btn el-white-btn" key="for-dietitians">
                        {isMobile ?  "For Pros" : "For Professionals"} <i className="icon-chevron-right" />
                    </a>
                );
            }

            if (showSignIn) {
                links.push(
                    <button key="signin-btn" className="el-medium-btn el-medium-btn el-white-btn" onClick={this.onClickSignIn}>Sign In</button>
                );
            }

            if (showSignUp) {
                links.push(
                    <Link key="signup-btn" to="/new-account" className="el-medium-btn el-medium-btn el-raspberry-btn">{isMobile ?  "Sign Up" : "Sign Up Free"}</Link>
                );
            }

            return <div className="header-btns">{links}</div>
        }

        if (hideNavigation) {
            return;
        }

        const shouldShowFirstPasswordTooltip = this.shouldShowFirstPasswordTooltip(user, location);
        const menuButton = (<SideNav location={this.props.location} />);

        const classNames = ['header-btns', 'logged-in-header-btns'];

        if (location.pathname !== '/meals') {
            classNames.push('on-secondary-page');
        }

        return (
            <div className={classNames.join(' ')}>
                <NotificationsDropdown />
                <GroceriesDropdown />

                {!shouldShowFirstPasswordTooltip && !user.is_password_expired ? menuButton :
                    <Popup
                        alwaysKeepOpen={true}
                        positionClassName="el-popup-top-right"
                        button={menuButton}>

                        {shouldShowFirstPasswordTooltip ?
                            <SetPassword title='Choose your Password' subTitle='Setting a password will secure your account!' showTerms={true} /> :
                            <SetPassword expired={user.is_password_expired} />}
                    </Popup>
                }
            </div>
        );
    }

    renderNetworkOrVersionNotifier = () => {
        const { isDisconnected } = this.props;
        const { latestVersion } = this.state;

        if (isDisconnected) {
            return (
                <div key="ver" className="version-notifier">
                    <Alert
                        iconType="no-network"
                        type="error no-network"
                        description={"No network. Please check your internet connection."}
                        onClickButton={() => location.reload(true)}
                        buttonText="reload"
                    />
                </div>
            );
        }


        if (latestVersion != getConfig('build_number')) {
            const reloadFunc = () => {
                Analytics.reloadForUpgrade({
                    'Old Version': latestVersion,
                    'New Version': getConfig('build_number')
                }).finally(() => location.reload(true));
            }
            return (
                <div key="ver" className="version-notifier">
                    <Alert
                        iconType="no-icon"
                        type="question"
                        description={"There's a new upgrade for EatLove. Please click to activate."}
                        onClickButton={() => reloadFunc()}
                        buttonText="reload"
                    />
                </div>
            );
        }

        return null;
    }

    renderHeaderHero = ()  => {
        const { user } = this.state;
        const { location, toolTitle, hideBackButton } = this.props;
        const environment = getConfig('environment');

        if (user && user.my_dietitian && location.pathname === '/meals')  {
            const { my_dietitian } = user;

            return [
                <div className="logo with-dietitian" key="logo">
                    <Link to="/">
                        <span className="assistive-text">EatLove Home Page</span>

                    </Link>
                </div>,
                (environment && environment !== 'prod' ?
                    <div className="env-indicator" key="env">{environment}</div>
                : null),
                <MyDietitianInfo key="rd" user={user} />,
            ].filter(v => v);
        }

        if (user && location.pathname !== '/meals') {
            // If we're not on the homepage and we don't have a tooltitle, we don't want to render anything for the hero.
            if (!toolTitle) {
                return null;
            }

            return (
                <p className="return-to-dashboard">
                    <Link to="/meals">
                        {!hideBackButton ? <i className="icon-chevron-left" /> : null}
                        {toolTitle}
                    </Link>
                </p>
            );

        }

        return [
            <div className="logo" key="logo">
                <Link to="/">
                    <span className="assistive-text">EatLove Home Page</span>
                </Link>
            </div>,
            (environment && environment !== 'prod' ?
                <div className="env-indicator" key="env">{environment}</div>
            : null),
        ];
    }

    renderDownForMaintenaceModal = () => {
        const { downForMaintenance } = this.state;

        if (!downForMaintenance) {
            return;
        }

        return <DownForMaintenanceModal message={downForMaintenance} />
    }

    shouldUseClearMode = () => {
        const { location } = this.props;
        const { user } = this.state;

        if (location.pathname === '/try-it-out') {
            return true;
        }

        if (['/new-account', '/accept-invite'].includes(location.pathname)) {
            return true;
        }

        return false;
    }

    render = () => {
        const { user, scrolled, windowScroll } = this.state;
        const { toolbarTitle, flatPageHeader, hideHeaderButtons } = this.props;

        const headerClassName = flatPageHeader ? "flat-page-header header-parent" : "header-parent";

        let clearMode = this.shouldUseClearMode();

        return (
            <header className={headerClassName} data-clear-mode={clearMode}>
                <header className="top-half-circle"></header>
                <div className="header-container">
                    {this.renderHeaderHero()}
                    {user || !hideHeaderButtons ? this.renderHeaderButtons() : null}
                    {this.renderNetworkOrVersionNotifier()}
                </div>
                {this.renderDownForMaintenaceModal()}
            </header>
        );
    }
}
