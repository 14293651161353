'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import UserStore from '../../../../../stores/UserStore';
import NutrientFilters from '../../../../Admin/Combos/NutrientFilters.react';

import './FiltersModal.scss';

export default class FiltersModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Make a deep copy of the original parameters
            initialParams: JSON.parse(JSON.stringify(props.params)),
        };
    }

    revertAndClose = () => {
        const { onChangeParams, closeModal } = this.props;
        const { initialParams } = this.state;

        onChangeParams(initialParams);
        closeModal();
    };

    toggleTag = (tag) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};
        params.filters.tags = params.filters.tags || [];

        if (params.filters.tags.includes(tag)) {
            params.filters.tags.splice(params.filters.tags.indexOf(tag), 1);
        } else {
            params.filters.tags.push(tag);
        }

        onChangeParams(params);
    };

    render() {
        const { params, onChangeParams, closeModal } = this.props;
        const { hide_nutrition = false } = UserStore.getPreferences();

        let tags = (params && params.filters && params.filters.tags) || [];

        return (
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                contentLabel="Add/Swap Recipe"
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container restaurant-menu-filters-modal">
                    <header>
                        <h2>Filter Results</h2>

                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container">
                        <div className="with-label">
                            <label>Meal</label>

                            <ul className="toggle-group">
                                {['Breakfast', 'Lunch', 'Dinner', 'Snack'].map((tag) => (
                                    <li>
                                        <button
                                            className="el-checkbox"
                                            data-active={tags.includes(tag)}
                                            onClick={() => this.toggleTag(tag)}
                                        >
                                            {tag}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="with-label">
                            <label>Dish Type</label>

                            <ul className="toggle-group">
                                {[
                                    'Appetizer',
                                    'Beverage',
                                    'Bread',
                                    'Condiment/Sauce',
                                    'Dessert',
                                    'Salad',
                                    'Sandwich',
                                    'Soup',
                                ].map((tag) => (
                                    <li>
                                        <button
                                            className="el-checkbox"
                                            data-active={tags.includes(tag)}
                                            onClick={() => this.toggleTag(tag)}
                                        >
                                            {tag}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {!hide_nutrition ? (
                            <NutrientFilters
                                params={params}
                                onChangeParams={onChangeParams}
                                nutrientWhitelist={['208', '203', '204', '205', '307']}
                            />
                        ) : null}
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.revertAndClose}>
                            Cancel
                        </button>
                        <button className="el-modal-ok-btn" onClick={closeModal}>
                            Apply
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
