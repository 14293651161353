'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import $ from 'jquery';
import indexBy from 'lodash.indexby';

import Paginator from '@components/Search/Paginator.react';
import SearchKeywords from '@components/Search/Filters/SearchKeywords.react';
import FiltersModal from '@components/Search/Modals/AddSwapRecipe/Restaurants/FiltersModal.react';
import MacrosPieChart from '@components/Widgets/MacrosPieChart.react';
import LocationInfo from '@components/Products/LocationInfo.react';
import ReportIssue from '@components/Widgets/ReportIssue.react';
import VariancesPopup from '@components/Widgets/VariancesPopup.react';

import FoodDetailsModal from '@components/Search/Modals/FoodDetailsModal.react';

import AuthStore from '@stores/AuthStore';
import UserActions from '@actions/UserActions';
import UserStore from '@stores/UserStore';

import allNutrients from '@tables/nutrients';
import Analytics from '@utils/Analytics';
import { fetchDocumentsById } from '@utils/Content';
import { getConfig } from '@utils/Env';
import { getDemographic } from '@utils/Nutrition';
import { roundForHumans, roundForHumansByUnit, getKmBetweenCoordinates } from '@utils/Math';
import {
    computeDefaultMealRx,
    getIdealsFromEnvelope,
    transformEnvelopeForRestaurants,
    convertEnvelopeToFilters,
} from '@utils/Nutrition';

import allRecommendations from '@tables/recommendations';

const nutrKeys = {
    208: {
        Units: '',
        NutrDesc: 'Calories',
    },
    VEG: {
        Units: ' servings',
        NutrDesc: 'Vegetables',
    },
    FRU: {
        Units: ' servings',
        NutrDesc: 'Fruit',
    },
};

const nutrientWhitelist = ['208', '203', '204', '205', '307'];

import './Menu.scss';

export default class RestaurantMenu extends Component {
    static propTypes = {
        mode: PropTypes.string,

        selectedLocation: PropTypes.object.isRequired,
    };

    static defaultProps = {
        mode: 'restaurants',
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
        onSelectProducts: PropTypes.func,
        showCreateCustom: PropTypes.func,

        meals: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,

        confirm: PropTypes.func,
        isMobile: PropTypes.bool,
    };

    static childContextTypes = {
        showFoodDetails: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        // Based on our addSwapContext and meals already scheduled on this date,
        // determine two sets of envelopes for this user.
        // 1. The per-meal envelope for mealType considering other meals today - Good Choice
        // 2. The per-meal envelope bare without modifications - Ok Choice
        const { smartChoice, okChoice, rx } = this.getChoiceEnvelopes(context.addSwapContext, context);

        const productParams = this.getBaselineProductParams(props, context);

        this.state = {
            productParams,
            smartChoices: [],
            totalSmartChoices: null,
            products: [],
            totalProducts: 0,

            selectedProducts: [],
            selectedNutrients: {},
            remaining: {},

            loading: true,
            loadingMore: false,

            smartChoice,
            okChoice,
            rx,

            brand: null,

            selectedProduct: null,
        };

        this.debounceSearchProducts = debounce(this.searchProducts, 400);
    }

    getChildContext = () => {
        return {
            showFoodDetails: this.showFoodDetails,
        };
    };

    componentDidMount = () => {
        this.searchProducts();
        this.loadBrand();
    };

    loadBrand = async () => {
        const { selectedLocation } = this.props;

        const brand = await fetchDocumentsById(selectedLocation.parent);

        this.setState({ brand });
    };

    getChoiceEnvelopes = ({ date, mealType, profile }, { meals, recipes, foods }) => {
        const allDayRx = profile.prescriptions.filter((p) => p.meal_type === 'all-day')[0];
        const rx =
            profile.prescriptions.filter((p) => p.meal_type === mealType)[0] ||
            (allDayRx && computeDefaultMealRx(allDayRx, mealType, true));

        // Make two deep clones of the prescription envelope
        const smartChoice = rx && allDayRx ? transformEnvelopeForRestaurants(rx.envelope, allDayRx.envelope) : null,
            okChoice = rx && allDayRx ? transformEnvelopeForRestaurants(rx.envelope, allDayRx.envelope, true) : null;

        return { smartChoice, okChoice, rx };
    };

    getBaselineProductParams = ({ selectedLocation }, { addSwapContext }) => {
        const { profile, mealType } = addSwapContext || {};
        let mealTypeTag = mealType;

        if (mealTypeTag === 'Dinner') {
            mealTypeTag = 'Main Dish';
        }

        const params = {
            language: profile?.language || 'en',
            types: ['food'],
            terms: '',
            filters: {
                tags: [mealTypeTag],
                product_type: 'Restaurant Dish',
                '!tags': [],
                '!ingredient_tags': profile?.preferences?.avoidances,
                brand_uuid: selectedLocation.parent,
            },
            size: 50,
        };

        return params;
    };

    onChangeProductParams = (productParams) => {
        this.setState({ productParams }, this.debounceSearchProducts);
    };

    showFoodDetails = (selectedProduct) => {
        this.setState({ selectedProduct });
    };

    resetProductParams = () => {
        this.onChangeProductParams(this.getBaselineProductParams(this.props, this.context));
    };

    massageVariances = (variances) => {
        return (variances || []).filter((variance) => {
            const nutrient = nutrKeys[variance.nutrNo] || allNutrients[variance.nutrNo];

            if (!nutrient) {
                return false;
            }

            const { value, min, max } = variance;

            if (min && value < min) {
                let roundedUnder = roundForHumansByUnit(min - value, nutrient.Units);

                if (!roundedUnder) {
                    return false;
                }
            }

            if (max && value > max) {
                let roundedOver = roundForHumansByUnit(value - max, nutrient.Units);

                if (!roundedOver) {
                    return false;
                }
            }

            return true;
        });
    };

    doesEnvelopeMatch = (nutrients, envelope) => {
        let variances = [];

        if (!envelope) {
            return { doesMatch: false, variances };
        }

        Object.keys(envelope).forEach((nutrNo) => {
            // Only consider nutrients in our whitelist
            if (!nutrientWhitelist.includes(nutrNo)) {
                return;
            }

            const range = envelope[nutrNo],
                value = nutrients[nutrNo];

            if (range.min && range.min > value) {
                variances.push({
                    nutrNo,
                    min: range.min,
                    value: value,
                    diff: range.min - value,
                });
            }

            if (range.max && range.max < value) {
                variances.push({
                    nutrNo,
                    max: range.max,
                    value: value,
                    diff: range.max - value,
                });
            }
        });

        variances = this.massageVariances(variances);
        const doesMatch = !variances.length;

        return { doesMatch, variances };
    };

    analyzeResults = (results) => {
        const { addSwapContext } = this.context;
        const { smartChoice, okChoice } = this.state;
        const { profile } = addSwapContext;
        const { avoidances, hide_nutrition = false } = profile.preferences || {};

        results.forEach((product) => {
            if (!(product && product.nutrients && product.nutrients.values)) {
                return;
            }

            const avoids = avoidances.filter((tag) => (product.ingredient_tags || []).includes(tag));
            const smart = this.doesEnvelopeMatch(product.nutrients.values, smartChoice),
                ok = this.doesEnvelopeMatch(product.nutrients.values, okChoice);

            product.isSmartChoice = hide_nutrition
                ? false
                : avoids.length == 0 && smart.doesMatch && !product.tags?.includes('Not a Smart Choice');
            product.isOkChoice = hide_nutrition
                ? false
                : avoids.length == 0 && ok.doesMatch && !product.tags?.includes('Not a Smart Choice');
            product.variances = hide_nutrition ? false : smart.variances;
            product.avoids = hide_nutrition ? false : avoids;
        });

        results.sort((a, b) => {
            if (a.isSmartChoice && !b.isSmartChoice) return -1;
            if (!a.isSmartChoice && b.isSmartChoice) return 1;

            if (a.isOkChoice && !b.isOkChoice) return -1;
            if (!a.isOkChoice && b.isOkChoice) return 1;

            return 0;
        });

        return results;
    };

    productSearchKey = null;

    addExclusionFilters = (results, filters = {}) => {
        filters['!uuid'] = filters['!uuid'] || [];
        results.forEach((r) => {
            if (!filters['!uuid'].includes(r.uuid)) {
                filters['!uuid'].push(r.uuid);
            }
        });

        return filters;
    };

    searchProducts = () => {
        const { selectedLocation } = this.props;
        const { profile } = this.context.addSwapContext;

        // Deep clone parameters
        const productSearchKey = JSON.stringify(this.state.productParams);

        if (productSearchKey === this.productSearchKey) {
            return;
        }

        this.productSearchKey = productSearchKey;
        const params = JSON.parse(productSearchKey);
        params.filters['!tags'].push('Not a Smart Choice'); // never include these as smart choices

        // Add smart choices filter & exclusion filters
        const { smartChoice } = this.state;
        params.filters = smartChoice ? convertEnvelopeToFilters(smartChoice, params.filters) : params.filters;

        this.setState({ loading: true });

        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
            body: JSON.stringify(params),
        }).then(
            (results) => {
                this.setState(
                    {
                        smartChoices: this.analyzeResults(results.elements),
                        totalSmartChoices: results.total,
                        loading: false,
                    },
                    () => {
                        if (results.elements.length < params.size) {
                            this.searchProductsFallback();
                        } else {
                            this.syncSelectedNutrients();
                        }
                    }
                );
            },
            (error) => this.setState({ loading: false })
        );

        Analytics.viewRestaurantMenu({
            Context: 'Add/Swap',
            location_uuid: selectedLocation.uuid,
            brand_uuid: selectedLocation.parent,
        });
    };

    searchProductsFallback = () => {
        const params = JSON.parse(this.productSearchKey);

        params.filters = this.addExclusionFilters(this.state.smartChoices, params.filters);

        this.setState({ loading: true });
        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
            body: JSON.stringify(params),
        }).then(
            (results) => {
                this.setState(
                    {
                        products: this.analyzeResults(results.elements),
                        totalProducts: results.total,
                        loading: false,
                    },
                    this.syncSelectedNutrients
                );
            },
            (error) => this.setState({ loading: false })
        );
    };

    showMoreProducts = () => {
        const { profile } = this.context.addSwapContext;
        const params = JSON.parse(JSON.stringify(this.state.productParams));

        this.setState({ loadingMore: true });
        const { smartChoices, products } = this.state;
        // Add smart choices filter & exclusion filters
        params.filters = this.addExclusionFilters(smartChoices, params.filters);
        params.filters = this.addExclusionFilters(products, params.filters);
        params.filters = convertEnvelopeToFilters(this.state.smartChoice, params.filters);

        params.filters['!ingredient_tags'] = profile.preferences.avoidances;

        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
            body: JSON.stringify(params),
        }).then(
            (results) => {
                this.setState({
                    smartChoices: this.state.smartChoices.concat(this.analyzeResults(results.elements)),
                    loadingMore: false,
                });

                if (results.elements.length < params.size) {
                    this.searchProductsFallback();
                } else {
                    this.syncSelectedNutrients();
                }
            },
            (error) => this.setState({ loading: false })
        );
    };

    showMoreProductsFallback = () => {
        const params = JSON.parse(JSON.stringify(this.state.productParams));

        this.setState({ loadingMore: true });
        const { smartChoices, products } = this.state;
        params.filters = this.addExclusionFilters(smartChoices, params.filters);
        params.filters = this.addExclusionFilters(products, params.filters);

        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
            body: JSON.stringify(params),
        }).then(
            (results) => {
                this.setState({
                    products: this.state.products.concat(this.analyzeResults(results.elements)),
                    loadingMore: false,
                });
            },
            (error) => this.setState({ loading: false })
        );
    };

    showFiltersModal = () => {
        Analytics.advancedSearch({ Context: 'Restaurant Search' });
        this.setState({ isFiltersModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isFiltersModalOpen: false, selectedProduct: null });
    };

    syncSelectedNutrients = () => {
        const { selectedPortions, smartChoice, smartChoices, products } = this.state;
        const { profile, mealType } = this.context.addSwapContext;

        const selectableUuids = smartChoices.map((r) => r.uuid).concat(products.map((r) => r.uuid));

        // Ensure that selectedProducts uuids are always included in either smartChoices or products array
        const selectedProducts = this.state.selectedProducts.filter((uuid) => selectableUuids.includes(uuid));

        const assets = indexBy(products.concat(smartChoices), 'uuid');
        const selectedNutrients = {};

        // First, aggregate the nutrients from the selected products
        selectedProducts.forEach((uuid) => {
            const food = assets[uuid];

            if (!food?.nutrients?.values) {
                return;
            }

            const {
                logged_unit = 'serving',
                logged_amount = 1,
                logged_grams,
            } = (selectedPortions && selectedPortions[food.uuid]) || {};

            // Aggregate nutrients from the selected products. We ignore the users portion size here
            // because products are not sold in variable portion sizes. You eat what you get from the store.
            Object.keys(food.nutrients.values).forEach((nutrNo) => {
                const value = food.nutrients.values[nutrNo] || 0;
                selectedNutrients[nutrNo] = selectedNutrients[nutrNo] || 0;

                if (logged_grams) {
                    selectedNutrients[nutrNo] += (value / food.grams_per_serving) * logged_grams;
                } else {
                    selectedNutrients[nutrNo] += value * logged_amount;
                }
            });
        });

        // Second, find or generate the rx for this profile / mealType
        const allDayRx = profile.prescriptions.filter((p) => p.meal_type === 'all-day')[0];

        if (!allDayRx) {
            this.setState({ selectedProducts, selectedNutrients, remaining: {} });
            return;
        }
        const rx =
            profile.prescriptions.filter((p) => p.meal_type === mealType)[0] ||
            (allDayRx && computeDefaultMealRx(allDayRx, mealType, true));

        // Third, count the remaining amounts left / over for macros and calories.
        const remaining = {};
        [208, 203, 204, 205, 307].forEach((nutrNo) => {
            // If the user is not tracking this macro, we can't calculate how much should remain. Skip it.
            if (!smartChoice[nutrNo]) {
                return;
            }

            // If for some weird reason the min is filled in, but the max isn't, we can subsitute the min value for
            // the max value. Don't tell anyone tho ;)
            const max = smartChoice[nutrNo].max || smartChoice[nutrNo].min;
            const value = selectedNutrients[nutrNo] || 0;

            remaining[nutrNo] = {
                percent: value < max ? Math.round((value / max) * 100) : 100,
                diff: max - value,
            };
        });

        this.setState({ selectedProducts, selectedNutrients, remaining });
    };

    toggleProduct = (uuid) => {
        let { selectedProducts } = this.state;

        if (selectedProducts.includes(uuid)) {
            selectedProducts.splice(selectedProducts.indexOf(uuid), 1);
        } else {
            selectedProducts.push(uuid);
        }

        this.setState({ selectedProducts }, this.syncSelectedNutrients);

        return false;
    };

    addSelectedProducts = () => {
        const { selectedLocation } = this.props;
        const { smartChoices, products, selectedProducts } = this.state;
        const { onSelectProducts } = this.context;

        if (!(selectedProducts && selectedProducts.length)) {
            return;
        }

        const allProducts = smartChoices.concat(products);

        onSelectProducts(
            selectedProducts.map((uuid) => allProducts.find((p) => p.uuid === uuid)),
            selectedLocation
        );
    };

    renderProductResult = (product, i) => {
        const { selectedProducts } = this.state;
        const { isMobile } = this.context;
        const { date, profile } = this.context.addSwapContext;
        const { hide_nutrition = false, rd_override = false } = (profile && profile.preferences) || {};

        let choiceType = 'normal';

        if (product.isSmartChoice) {
            choiceType = 'smart';
        } else if (product.isOkChoice) {
            choiceType = 'ok';
        }

        const kcalPerServing = (product.nutrients && product.nutrients.values && product.nutrients.values[208]) || 0;
        let kcal = kcalPerServing;
        const variancesPopupPositionClass = isMobile ? 'el-popup-top-right-center' : 'el-popup-right-bottom';

        return (
            <li
                key={i}
                className="product-result-item"
                data-choice-type={choiceType}
                data-selected={selectedProducts && selectedProducts.includes(product.uuid)}
            >
                <button
                    className="select-product-btn"
                    data-selected={selectedProducts && selectedProducts.includes(product.uuid)}
                    onClick={(ev) => this.toggleProduct(product.uuid)}
                >
                    &nbsp;
                </button>

                <div className="product-stats">
                    {product?.variances?.length || product?.avoids?.length ? (
                        <VariancesPopup
                            contextName="Restaurant Meal"
                            variances={product.variances}
                            avoidances={product.avoids}
                            positionClass={variancesPopupPositionClass}
                        />
                    ) : null}

                    {choiceType !== 'normal' ? <span className="choice-type">{choiceType} choice</span> : null}

                    {!(hide_nutrition || rd_override) ? <span className="kcal">{roundForHumans(kcal)}</span> : null}
                </div>

                <div className="product-result-container">
                    <h4 onClick={() => this.showFoodDetails(product)}>{product.name}</h4>
                    {product.description ? (
                        <p onClick={() => this.showFoodDetails(product)}>{product.description}</p>
                    ) : (
                        product.description
                    )}
                    <p onClick={() => this.showFoodDetails(product)}>
                        {product.serving_description ? <span>Serving Size: {product.serving_description}</span> : null}
                        {product.grams_per_serving && !(hide_nutrition || rd_override) ? (
                            <span>{roundForHumans(product.grams_per_serving)} grams</span>
                        ) : null}
                    </p>
                </div>
            </li>
        );
    };

    showCreateCustom = () => {
        const { showCreateCustom } = this.context;

        showCreateCustom({
            defaultCustomType: 'restaurant',
        });
    };

    renderFiltersModal = () => {
        const { productParams, isFiltersModalOpen } = this.state;

        if (!isFiltersModalOpen) {
            return;
        }

        return (
            <FiltersModal
                params={productParams}
                onChangeParams={this.onChangeProductParams}
                closeModal={this.closeModal}
            />
        );
    };

    renderProductDetailsModal = () => {
        const { selectedLocation } = this.props;
        const { selectedProduct } = this.state;

        if (!selectedProduct) {
            return;
        }

        const onSelectProduct = (product) => {
            this.toggleProduct(product.uuid);
            this.closeModal();
        };

        return (
            <FoodDetailsModal
                closeModal={this.closeModal}
                hideFoodUnitSelector={true}
                food={selectedProduct}
                location={selectedLocation}
                onSelectFood={onSelectProduct}
            />
        );
    };

    render = () => {
        const { selectedLocation, returnToMap } = this.props;

        const {
            loading,
            loadingMore,
            brand,
            selectedNutrients,
            selectedProducts,
            remaining,
            rx,
            products,
            productParams,
            totalProducts,
            smartChoices,
            totalSmartChoices,
        } = this.state;

        const { profile } = this.context.addSwapContext;

        const currentLocation = this.map && this.map.currentLocation;
        const defaultNutrients = getIdealsFromEnvelope(rx?.envelope || {}, 1);
        const { hide_nutrition = false, rd_override = false } = profile?.preferences || {};

        const demographic = getDemographic(profile);
        const dri = {
            ...allRecommendations['all-ages'],
            ...allRecommendations[demographic],
        };

        ['203', '204', '205'].forEach((nutrNo) => {
            if (!defaultNutrients[nutrNo]) {
                defaultNutrients[nutrNo] = dri[nutrNo];
            }
        });

        return (
            <div className="restaurant-menu">
                <header>
                    <button className="return-to-locations-btn" onClick={returnToMap}>
                        <i className="icon-chevron-left" />
                        Return to Restaurant List
                    </button>
                </header>

                <LocationInfo brand={brand} location={selectedLocation} currentLocation={currentLocation} />

                {!(hide_nutrition || rd_override) ? (
                    <section className="nutrition-analysis">
                        <h3>Recommended Meal Limits:</h3>

                        {remaining[208] && remaining[208].diff > 0 ? (
                            <div className="calories-left">
                                <em>{Math.round(remaining[208].diff)}</em> cals left
                            </div>
                        ) : null}

                        {remaining[208] && remaining[208].diff < 0 ? (
                            <div className="calories-over">
                                <em>{Math.abs(Math.round(remaining[208].diff))}</em> cals over
                            </div>
                        ) : null}

                        <MacrosPieChart nutrients={selectedNutrients} macrosGoals={defaultNutrients} />

                        {remaining[205] && remaining[205].diff > 0 ? (
                            <div className="macro-left">
                                <em>CARBS</em>
                                <div className="small-progress-bar">
                                    <div
                                        className="small-progress-bar-fill"
                                        style={{ width: remaining[205].percent + '%' }}
                                    ></div>
                                </div>
                                {Math.round(remaining[205].diff)} g left
                            </div>
                        ) : null}

                        {remaining[205] && remaining[205].diff < 0 ? (
                            <div className="macro-over">
                                <em>CARBS</em>
                                <div className="small-progress-bar">
                                    <div className="small-progress-bar-fill" style={{ width: '100%' }}></div>
                                </div>
                                {Math.abs(Math.round(remaining[205].diff))} g over
                            </div>
                        ) : null}

                        {remaining[203] && remaining[203].diff > 0 ? (
                            <div className="macro-left">
                                <em>PROTEIN</em>
                                <div className="small-progress-bar">
                                    <div
                                        className="small-progress-bar-fill"
                                        style={{ width: remaining[203].percent + '%' }}
                                    ></div>
                                </div>
                                {Math.round(remaining[203].diff)} g left
                            </div>
                        ) : null}
                        {remaining[203] && remaining[203].diff < 0 ? (
                            <div className="macro-over">
                                <em>PROTEIN</em>
                                <div className="small-progress-bar">
                                    <div className="small-progress-bar-fill" style={{ width: '100%' }}></div>
                                </div>
                                {Math.abs(Math.round(remaining[203].diff))} g over
                            </div>
                        ) : null}

                        {remaining[204] && remaining[204].diff > 0 ? (
                            <div className="macro-left">
                                <em>FAT</em>
                                <div className="small-progress-bar">
                                    <div
                                        className="small-progress-bar-fill"
                                        style={{ width: remaining[204].percent + '%' }}
                                    ></div>
                                </div>
                                {Math.round(remaining[204].diff)} g left
                            </div>
                        ) : null}
                        {remaining[204] && remaining[204].diff < 0 ? (
                            <div className="macro-over">
                                <em>FAT</em>
                                <div className="small-progress-bar">
                                    <div className="small-progress-bar-fill" style={{ width: '100%' }}></div>
                                </div>
                                {Math.abs(Math.round(remaining[204].diff))} g over
                            </div>
                        ) : null}

                        {remaining[307] && remaining[307].diff > 0 ? (
                            <div className="macro-left">
                                <em>SODIUM</em>
                                <div className="small-progress-bar">
                                    <div
                                        className="small-progress-bar-fill"
                                        style={{ width: remaining[307].percent + '%' }}
                                    ></div>
                                </div>
                                {Math.round(remaining[307].diff)} mg left
                            </div>
                        ) : null}
                        {remaining[307] && remaining[307].diff < 0 ? (
                            <div className="macro-over">
                                <em>SODIUM</em>
                                <div className="small-progress-bar">
                                    <div className="small-progress-bar-fill" style={{ width: '100%' }}></div>
                                </div>
                                {Math.abs(Math.round(remaining[307].diff))} mg over
                            </div>
                        ) : null}
                    </section>
                ) : null}

                <section className="product-results-list" data-showing-nutrients={!(hide_nutrition || rd_override)}>
                    <header>
                        <SearchKeywords
                            params={productParams}
                            className="product-keyword-search"
                            onChangeParams={this.onChangeProductParams}
                            placeholder="Search menu items"
                        />

                        <button className="open-filters-btn" onClick={this.showFiltersModal}>
                            <i className="icon-settings4" />
                        </button>
                    </header>

                    {loading && !smartChoices.length && !products.length ? (
                        <div className="loading">
                            <i className="icon-spinner2" />
                        </div>
                    ) : null}

                    <ul>
                        {smartChoices.map(this.renderProductResult)}
                        {products.map(this.renderProductResult)}
                    </ul>

                    <footer>
                        <Paginator
                            loading={loading}
                            loadingMore={loadingMore}
                            total={totalSmartChoices}
                            results={smartChoices}
                            showMore={this.showMoreProducts}
                        />

                        <Paginator
                            loading={loading}
                            loadingMore={loadingMore}
                            total={totalProducts}
                            results={products}
                            showMore={this.showMoreProductsFallback}
                        />
                    </footer>
                </section>

                <footer>
                    <ReportIssue location={selectedLocation} />
                    <button className="cancel-btn" onClick={returnToMap}>
                        Cancel
                    </button>
                    <button
                        disabled={selectedProducts.length ? false : true}
                        className="ok-btn"
                        onClick={this.addSelectedProducts}
                    >
                        Add{selectedProducts.length ? ' (' + selectedProducts.length + ')' : null}
                    </button>
                </footer>
                {this.renderFiltersModal()}
                {this.renderProductDetailsModal()}
            </div>
        );
    };
}
